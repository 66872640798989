import React from "react"
import { Input } from "antd"

const { Search } = Input

const SearchBar = ({ filterSearch }) => (
  <Search
    placeholder="SEARCH"
    onSearch={value => {
      filterSearch("search", value, value)
    }}
    style={{ width: 200 }}
  />
)

export default SearchBar
