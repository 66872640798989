import React from "react"
import categoriesStyle from "./style.module.scss"

const Categories = ({ categories, filterCategories }) => (
  <div className={categoriesStyle.categoryModule}>
    <h3 className={categoriesStyle.categoriesTitle}>CATEGORIES</h3>
    {categories.nodes.map(item => (
      <React.Fragment>
        {item.count === 0 ? (
          ""
        ) : (
          <div key={item.id}>
            <button
              className={categoriesStyle.list}
              onClick={() => filterCategories("category", item.id, item.name)}
            >
              <h4 className={categoriesStyle.category}>{item.name}</h4>
              <span className={categoriesStyle.count}>({item.count})</span>
            </button>
          </div>
        )}
      </React.Fragment>
    ))}
  </div>
)

export default Categories
