import React from "react"
import { getParam, setCookie } from "../../util/google-tracking"
import footerStyle from "./style.module.scss"
import FooterExtended from "./footer-extended"
import { StaticQuery, graphql } from "gatsby"
import GrioOffices from "./grio-offices"
import NavigationFooter from "./navigation-footer"
import { getYear } from "../../util/get-year"

class Footer extends React.Component {
  componentDidMount() {
    const gclid = getParam("gclid")
    if (gclid) {
      const gclsrc = getParam("gclsrc")
      if (!gclsrc || -1 !== gclsrc.indexOf("aw")) {
        setCookie("gclid", gclid, 90)
      }
    }
  }
  render() {
    const footerExtendedContent = this.props.footerExtendedContent
    const showFooterExtended = this.props.showFooterExtended

    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulV4FooterNavigation {
              findUs
              facebookLink
              linkedInLink
              legal
              copyright
              companyName
              facebookIcon {
                file {
                  url
                }
              }
              linkedInIcon {
                file {
                  url
                }
              }
              grioLogo {
                file {
                  url
                }
              }
              grioOffices {
                id
                addressLine1
                addressLine2
                officeCityName
                officeIcon {
                  file {
                    url
                  }
                }
              }
              navigation {
                list {
                  link
                  text
                }
                name
                title
              }
              newsletter {
                name
                inputText
                submitLink
                submitText
                submittedText
                title
                description
              }
            }
          }
        `}
        render={data => {
          const footerContent = data.contentfulV4FooterNavigation
          return (
            <div>
              {showFooterExtended && (
                <FooterExtended mod={footerExtendedContent} />
              )}
              <div className={footerStyle.footer}>
                <div>
                  <NavigationFooter
                    siteMap={footerContent.navigation}
                    grioLogo={footerContent.grioLogo}
                    newsletter={footerContent.newsletter}
                  />
                  <GrioOffices offices={footerContent.grioOffices} />
                </div>

                <div className={footerStyle.farewell}>
                  <div className={footerStyle.findUsContainer}>
                    <p className={footerStyle.findUsText}>
                      {footerContent.findUs}
                    </p>
                    <a href={footerContent.facebookLink}>
                      <img
                        alt={footerContent.facebookLink}
                        src={footerContent.facebookIcon.file.url}
                        className={footerStyle.socialMediaIcon}
                      />
                    </a>
                    <a href={footerContent.linkedInLink}>
                      <img
                        alt={footerContent.linkedInLink}
                        src={footerContent.linkedInIcon.file.url}
                        className={footerStyle.socialMediaIcon}
                      />
                    </a>
                  </div>
                  <span className={footerStyle.copyright}>{`${
                    footerContent.copyright
                  } ${getYear()} ${footerContent.companyName}`}</span>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default Footer
