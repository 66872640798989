import React from "react"
import navigationFooterStyle from "./style.module.scss"
import { setEmailMailchimp } from "../../../util/send-form-data"
import config from "../../../config"

class Newsletter extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      submited: false,
    }
  }
  render() {
    const newsletter = this.props.newsletter
    const state = this.state
    return (
      <div className={navigationFooterStyle.newsletterContainer}>
        <div className={navigationFooterStyle.columnsContainer}>
          <div className={navigationFooterStyle.listContainer}>
            <h3 className={navigationFooterStyle.columnTitle}>
              {newsletter.title}
            </h3>

            {!this.state.submited ? (
              <React.Fragment>
                <p className={navigationFooterStyle.linkText}>
                  {newsletter.description}
                </p>
                <div className={navigationFooterStyle.formContainer}>
                  <form
                    id="newsletterForm"
                    onSubmit={e => {
                      e.preventDefault()
                      const endpoint = config.mailchimpEndpoint
                      setEmailMailchimp(this.state.email, endpoint)
                        .then(() => {
                          this.setState({
                            email: "",
                            submited: true,
                          })
                        })
                        .catch(() => console.log("Email Submition Failed"))
                    }}
                  >
                    <input
                      required
                      aria-label="email"
                      id="email"
                      type="email"
                      name="email"
                      placeholder={newsletter.inputText}
                      value={state.email}
                      onChange={event =>
                        this.setState({
                          email: event.target.value,
                        })
                      }
                    />
                    <input
                      className={`${navigationFooterStyle.button} ${navigationFooterStyle.buttonText}`}
                      type="submit"
                      value={newsletter.submitText}
                    />
                  </form>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className={navigationFooterStyle.linkText}>
                  {newsletter.submittedText}
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Newsletter
