import React from "react"
import { Link } from "gatsby"
import navigationFooterStyle from "./style.module.scss"
import Newsletter from "./newsletter"
import { linkPath } from "../../../util/path"

const NavigationFooter = ({ siteMap, grioLogo, newsletter }) => (
  <div className={navigationFooterStyle.navigationFooter}>
    <div className={navigationFooterStyle.logoAndNavigation}>
      <img
        className={navigationFooterStyle.grioLogo}
        alt={grioLogo.file.url}
        src={grioLogo.file.url}
      />
      <div className={navigationFooterStyle.columnsContainer}>
        {siteMap.map(column => (
          <div
            key={column.title}
            className={navigationFooterStyle.listContainer}
          >
            {!column.title.includes("Contact") ? (
              <h3 className={navigationFooterStyle.columnTitle}>
                {column.title}
              </h3>
            ) : (
              <a href={`${linkPath}${column.list[0].link}`}>
                <h3
                  className={`${navigationFooterStyle.linkTitle} ${navigationFooterStyle.columnTitle}`}
                >
                  {column.title}
                </h3>
              </a>
            )}

            {column.list.map(link => (
              <React.Fragment key={link.text}>
                {link.text.includes("Blog") ? (
                  <Link className={navigationFooterStyle.linkText} to={"/"}>
                    {link.text}
                  </Link>
                ) : !link.text.includes("Contact") ? (
                  <a
                    href={`${linkPath}${link.link}`}
                    className={navigationFooterStyle.linkText}
                  >
                    {link.text}
                  </a>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        ))}
        <Newsletter newsletter={newsletter}></Newsletter>
      </div>
    </div>
  </div>
)

export default NavigationFooter
