import React from "react"
import grioOfficesStyle from "./style.module.scss"

const GrioOffices = ({ offices }) => (
  <div className={grioOfficesStyle.grioOffices}>
    <div className={grioOfficesStyle.locationsContainer}>
      {offices.map(office => (
        <div key={office.id} className={grioOfficesStyle.cityContainer}>
          <div className={grioOfficesStyle.imageContainer}>
            <img
              className={grioOfficesStyle.officeIcon}
              src={office.officeIcon.file.url}
              alt={office.officeCityName}
            />
          </div>

          <div className={grioOfficesStyle.cityAddressContainer}>
            <div className={grioOfficesStyle.cityAddressTitle}>
              {office.officeCityName}
            </div>
            <div className={grioOfficesStyle.cityAddressLine}>
              {office.addressLine1}
            </div>
            <div className={grioOfficesStyle.cityAddressLine}>
              {office.addressLine2}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default GrioOffices
