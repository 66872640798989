import React from "react"
import Img from "gatsby-image"
import footerExtendedStyle from "./style.module.scss"
import { linkPath } from "../../../util/path"

const FooterExtended = ({ mod }) => (
  <div className={footerExtendedStyle.footerExtendedContainer}>
    <div className={footerExtendedStyle.spaceBetween}>
      {mod.content.map((item, index) => (
        <div key={item.id} className={footerExtendedStyle.contentBox}>
          <div className={footerExtendedStyle.icon}>
            <Img
              className={`${footerExtendedStyle.iconImage} ${index === 0 &&
                footerExtendedStyle.first}`}
              alt={item.icon.title}
              fluid={item.icon.fluid}
            />
          </div>
          <div className={footerExtendedStyle.textBox}>
            <h2 className={footerExtendedStyle.title}>{item.title}</h2>
            <p className={footerExtendedStyle.description}>
              {item.description}
            </p>
            <a
              className={footerExtendedStyle.link}
              href={`${linkPath}${item.link}`}
              alt={item.linkText}
            >
              <div className={footerExtendedStyle.arrow}>
                <Img
                  className={footerExtendedStyle.arrowImage}
                  alt={item.arrow.title}
                  fluid={item.arrow.fluid}
                />
              </div>
              <p className={footerExtendedStyle.goToLink}>{item.linkText}</p>
            </a>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default FooterExtended
