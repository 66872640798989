export const setCookie = (a, d, b) => {
  const c = new Date()
  c.setTime(c.getTime() + 864e5 * b)
  b = "; expires=" + c.toGMTString()
  document.cookie = a + "=" + d + b
}
export const getParam = a => {
  return (
    (a = RegExp("[?&]" + a + "=([^&]*)").exec(window.location.search)) &&
    decodeURIComponent(a[1].replace(/\+/g, " "))
  )
}
export const readCookie = name => {
  const n = name + "="
  const cookie = document.cookie.split(";")
  for (var i = 0; i < cookie.length; i++) {
    let c = cookie[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(n) === 0) {
      return c.substring(n.length, c.length)
    }
  }
  return null
}
